<template>
  <laboratories-module-component></laboratories-module-component>
</template>

<script>
import LaboratoriesModuleComponent from "@/components/admin/modules/LaboratoriesModuleComponent";

export default {
  name: "LaboratoriesModule",
  title: "Gestión de Laboratorios | Baja California Health Tourism",
  components: { LaboratoriesModuleComponent },
};
</script>

<style scoped></style>
